export default {
  state: {
    typesWorksList: [
      {
        id: 1,
        text: "Установка внутреннего блока на ровную твердую стену (кирпич, бетон, пеноблок)",
      },
      {
        id: 2,
        text: "Установка внешнего блока под окно или в легкодоступном месте, крепление стандартных кронштейнов внешнего блока на дюбель-саморез длиной до 70 мм",
      },
      {
        id: 3,
        text: "Монтаж производится без использования промышленных альпинистов, автовышек, строительных лесов. Если внешний блок устанавливается на высоте не более 3-х метров от земли, то для монтажа используется стремянка",
      },
      {
        id: 4,
        text: "Бурение сквозного отверстия глубиной не более 60 см",
      },
      {
        id: 5,
        text: "Проведение между блоками электрокабеля и фреоновой трассы (2 медные трубы, теплоизоляция, межблочный кабель) длинной до 3 метров",
      },
      {
        id: 6,
        text: " Отвод конденсата с внутреннего блока под уклоном вниз от его нижнего угла (не более 3 метров)",
      },
      {
        id: 7,
        text: "Укладка коммуникаций внутри помещения в короб (белый) 60х60 мм, либо 60х80 мм длинной до 1 метров, или заранее подготовленную клиентом штробу",
      },
      {
        id: 8,
        text: "Подводка кабеля к источнику электропитания не входит",
      },
      {
        id: 9,
        text: "Работы по наладке и запуску кондиционера",
      },
    ],

    schemes: [
      {
        id: 1,
        titleSchemes: "Установка наружного блока под окном",
        text: "Это наиболее часто практикуемая схема установки кондиционера. Внутренний блок сплит-системы устанавливается на стене, примыкающей к фасадной, а наружный блок вывешивается под окно. Удобство данной схемы наряду с простотой монтажа и дальнейшего обслуживания кондиционера в том, что внутренний блок создает направленный поток вдоль окна, не затрагивая в большинстве случаев зону постоянного нахождения людей. Межблочные коммуникации, если кондиционер устанавливается в процессе ремонта квартиры, рекомендуется уложить в стену, выштробив в ней необходимое углубление. Если в квартире уже сделан ремонт, то магистраль можно прикрыть декоративным коробом.",
        imageSchemes1: require("@/assets/image/schemes/montag1.jpg"),
        imageSchemes2: require("@/assets/image/schemes/montag2.jpg"),
        imageSchemes3: require("@/assets/image/schemes/montag3.jpg"),
      },
      {
        id: 2,
        titleSchemes: "Установка наружного блока между окнами",
        text: "Данная схема обладает всеми достоинствами первого варианта, кроме того, межблочные коммуникации предельно малы и не создают проблем как интерьеру помещения, так и фасаду дома. Снаружи такой вариант монтажа выглядит очень аккуратно и естественно.",
        imageSchemes1: require("@/assets/image/schemes/montag4.jpg"),
        imageSchemes2: require("@/assets/image/schemes/montag5.jpg"),
        imageSchemes3: require("@/assets/image/schemes/montag6.jpg"),
      },
      {
        id: 3,
        titleSchemes: "Установка наружного блока возле балкона",
        text: "Данная схема аналогична  первому варианту. Большим плюсом ее является то, что наружный блок кондиционера абсолютно не загораживает вид из окна. Однако, балкон должен быть либо не застеклен, либо иметь открывающиеся с торца окна. В противном случае как при монтаже, так и при обслуживании кондиционера потребуются услуги промышленного альпиниста.",
        imageSchemes1: require("@/assets/image/schemes/montag7.jpg"),
        imageSchemes2: require("@/assets/image/schemes/montag8.jpg"),
        imageSchemes3: require("@/assets/image/schemes/montag9.jpg"),
      },
       {
        id: 4,
        titleSchemes: "Установка наружного блока на балконном ограждении",
        text: "В данном случае наружный блок кондиционера устанавливается непосредственно на балконном ограждении. Единственное условие - балконное ограждение, к которому крепится наружный блок, должно быть для этого пригодным.",
        imageSchemes1: require("@/assets/image/schemes/montag10.jpg"),
        imageSchemes2: require("@/assets/image/schemes/montag11.jpg"),
        imageSchemes3: require("@/assets/image/schemes/montag12.jpg"),
      },



    ],
  },
  getters: {
    typesWorksList(state){
      return state.typesWorksList;
  },
  
  schemes(state){
    return state.schemes;
},
  


  },
  mutations: {},
  actions: {},
  modules: {
   
  },
};
